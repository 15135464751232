export default function initSlickWithArrowEvent($element, configuration) {
    if (!configuration.infinite) {
        $element.on('init', function (event, slick) {
            slick.$prevArrow.css('visibility', 'hidden');
        })
    }

    $element.not('.slick-initialized').slick(configuration);

    if (!configuration.infinite) {
        $element.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            let slideToShow = configuration.slidesToShow;
            if (null !== slick.activeBreakpoint) {
                slideToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToScroll;
            }

            slick.$prevArrow.css('visibility', 0 === nextSlide ? 'hidden' : 'visible')
            slick.$nextArrow.css('visibility', 0 >= (slick.slideCount - (nextSlide + slideToShow)) ? 'hidden' : 'visible')
        });
    }

    return $element;
}